import React from 'react';
//import logo from './logo.svg';
import './App.css';

function App() {
  return (
    <div class="container" id="alter-applyer-container">
      <div class="row alter-applyer-row h-100"> 
        {/* <div className="col-md-6 offset-md-3 logos_container">
          <img src={require('./img/logo_volkeno.png')} className="img-responsive logo-img" />  
          <img src={require('./img/logo_bakeli.png')} className="img-responsive logo-img" />  
        </div>  */}
        <h1 className="text-center page-title"> Alter Apply Bakeli </h1>
        <div className="col-md-6 offset-md-3 button-container">
        <div className="jeemacoder-apply-btn-container">
          <h3 className="jca-title"><span className="jeema">Jéema</span><span className="coder">Coder</span> <span className="jc-year">2019</span></h3>
          <span className="slogan"> <i>Formation gratuite d'une semaine du 23 au 28 septembre 2019</i> </span>
          <a href="http://apply.bakeli.tech/#/inscription-bootcamp/092019100936-jeema-coder-201" className="form-link">
            <button class="ui teal vertical animated button">
              <div class="hidden content">JéemaCoder 2019 - Inscription</div>
              <div class="visible content"><i aria-hidden="true" class="hand pointer icon"></i></div> 
            </button>
          </a>
        </div>
        </div>
      </div>
    </div>
  );
}

export default App;
